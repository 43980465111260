import React from 'react'
import Yotpo from '../../../components/Integrations/IntegrationsDetails/Yotpo'
import PageSeo from '../../../components/SEO'

function index() {
    return (
        <div>
            <PageSeo pageTitle="Yotpo"/>
            <Yotpo/>
        </div>
    )
}

export default index
